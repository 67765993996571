import React from 'react';
import ReactDOM from 'react-dom';

import FieldRadio from './../../fieldradio.js';
import Specialization from './specialization.js';

/*
 *
 */
class Skill extends React.Component {
  /* */
  constructor( props ) {
    super( props );
  }

  /* */
  render( ) {
    var values = ['D2', 'D4', 'D6', 'D8', 'D10', 'D12'];
    var labels = ['D2', 'D4', 'D6', 'D8', 'D10', 'D12'];

    if( this.props.name == 'CONDITIONING' ) {
      values = ['+1', '+2', '+3', '+4', '+5', '+6'];
      labels = ['+1', '+2', '+3', '+4', '+5', '+6'];
    }

    var skills =  ( <div className={"specializations specializations-" + this.props.name} >
                      <div className={"label-specialization label-specialization-" + this.props.name}>
                        SPECIALIZATION
                      </div>
                      <Specialization
                        skill={this.props.name}
                        number="1" />
                      <Specialization
                        skill={this.props.name}
                        number="2" />
                      <Specialization
                        skill={this.props.name}
                        number="3" />
                    </div>);
    return(
      <div className="skill-wrapper" >
        <div className="skill" >
          <div className="skill-info" >
            <div className="skill-title">{this.props.name}</div>
              <FieldRadio
                className="skill-dice"
                name={"skill-dice-" + this.props.name}
                values={values}
                labels={labels} />

              {this.props.simple == "true" ? '' : skills}
          </div>
        </div>
      </div>
    );
  }
}

export default Skill;
