import axios from 'axios';
import AuthService from './auth.service.js';

/* */
import { API_TRANSFORMERS_URL } from './config.api.js';

/* */
const createCampaign = ( ) => {
    return axios.post( API_TRANSFORMERS_URL + AuthService.getToken( ) + '/campaign/create' );
}

/* */
const getCampaigns = ( ) => {
  return axios.get( API_TRANSFORMERS_URL + AuthService.getToken( ) + '/campaigns' );
}

/* */
const getCampaign = ( id ) => {
  return axios.get( API_TRANSFORMERS_URL + AuthService.getToken( ) + '/campaign/' + id );
};

/* */
const getParty = ( id ) => {
  return axios.get( API_TRANSFORMERS_URL + AuthService.getToken( ) + '/campaign/' + id + '/party' );
}

/* */
const getAlly = ( campaignID, allyID ) => {
  return axios.get( API_TRANSFORMERS_URL + AuthService.getToken( ) + '/campaign/' + campaignID + '/ally/' + allyID );
}

/* */
const CampaignService = {
  getCampaign,
  getCampaigns,
  createCampaign,
  getParty,
  getAlly,
}

/* */
export default CampaignService;
