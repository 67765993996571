import React from 'react';
import ReactDOM from 'react-dom';
import Field from './field.js';

import Autobot from './images/transformers-autobot.svg';
import Emitter from './common/emitter.js';
import CharacterService from './services/character.service.js';



class FieldRadio extends Field {
  /* */
  constructor( props ) {
    super( props );

    this.handleClick = this.handleClick.bind( this );

    /* */
    this.state = {
      name:       this.props.name,
      value:      '',
      inForm:     false,
      label:      this.props.label,
      labelAfter: this.props.labelAfter == 'true' ? true : false,
      noLabel:    this.props.noLabel == 'true' ? true : false,
      className:  this.props.className,
      values:     this.props.values,
      labels:     this.props.labels,
      images:     this.props.images,
    }
  }

  loadEmitter( ) {
    var me = this;

    // Listen for emitter - character:load
    Emitter.on( 'character:load', function( c ) {
      Object.keys(c).map((v, n) => {
        if( v == me.state.name ) {
          var tmp = c[v];

          me.setState( { value: tmp } );
          try {
            document.getElementById( v + '-' + tmp ).checked = true;
          }
          catch( e ) {}

          return;
        }
      });
    });
  }


  /* */
  handleClick( event ) {
    const target  = event.target;
    const value   = target.value;
    const name    = target.name;
    var newValue= null;

    if( value == this.state.value ) {
      this.setState({ value: '' });
      target.checked = false;
      newValue = '';
    }
    else {
      this.setState({ value: value });
      target.checked = true;
      newValue = value;
    }

    var character = CharacterService.setCharacterValue( localStorage.getItem( 'characterID' ), name, newValue );
  }

  /* */
  handleInputChange( event ) {
    const target  = event.target;
    const value   = target.value;
    const name    = target.name;

    //this.setState({ value: value });
  }

  handleBlur( event ) {}

  /* */
  render( ) {
    return(
      <div className={this.state.className + " " + this.state.name + '-wrapper field-wrapper field-radio-wrapper'} >
        {this.props.values.map((value,index) => {
          var hasImages = Array.isArray(this.state.images);
          var img = hasImages ? <img src={this.state.images[index]} alt="Logo" className={"symbol-" + value} /> : '';

          var a = '';
          var b = <div className="field-label" >
                    {this.state.labels[ index ]}
                  </div>

          if( hasImages ) {
            b = <div className="field-label-main" >
                  <div className="field-label-img" >
                    {img}
                  </div>
                  {b}
                </div>;
          }

          if( this.state.labelAfter ) {
            a = b;
            b = '';
          }

          return( <div className={this.state.name + '-' + value + '-wrapper'} >
                    {b}
                    <div className={this.state.name + '-' + value + '-radio-wrapper'} >
                      <input type="radio"
                        id      = {this.state.name + '-' + value}
                        name    = {this.state.name}
                        value   = {value}
                        onChange= {this.handleInputChange}
                        onBlur  = {this.handleBlur}
                        onClick = {this.handleClick}
                      />
                    </div>
                    {a}
                  </div>
                ) } ) }
      </div>
    );
  }
}

export default FieldRadio;
