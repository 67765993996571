import axios from 'axios';
import AuthService from './auth.service.js';

/* */
import { API_TRANSFORMERS_URL } from './config.api.js';

/* */
const createCharacter = ( ) => {
    return axios.post( API_TRANSFORMERS_URL + AuthService.getToken( ) + '/character/create' );
}

/* */
const getCharacters = ( ) => {
  return axios.get( API_TRANSFORMERS_URL + AuthService.getToken( ) + '/character/all' );
}

/* */
const getCharacter = ( id ) => {
  return axios.get( API_TRANSFORMERS_URL + AuthService.getToken( ) + '/character/' + id );
};

/* */
const setCharacterValue = ( id, name, value ) => {
  return axios.post( API_TRANSFORMERS_URL + AuthService.getToken( ) + '/character/' + id, { name, value } );
};

/* */
const CharacterService = {
  getCharacter,
  getCharacters,
  setCharacterValue,
  createCharacter
}

/* */
export default CharacterService;
