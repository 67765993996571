import React from 'react';
import ReactDOM from 'react-dom';

import Field from './../../field.js';
import FieldArea from './../../fieldarea.js';
import FieldRadio from './../../fieldradio.js';

import Autobot from './../../images/transformers-autobot.svg';
import Decepticon from './../../images/transformers-decepticon.svg';

/* */
class CharacterInfo extends React.Component {
  /* */
  constructor( props ) {
    super( props );
  }

  /* */
  render( ) {
    return(
      <div className="info-wrapper" >
        <div className="main-header-wrapper" >
          <div className="main-header" >
            <div className="main-header-left" >
            </div>
            <div className="main-header-transformers" >Transformers</div>
            <div className="main-header-mtmte" >More Than Meets the Eye</div>
            <div className="main-header-rpg" >Roleplaying Game</div>

            <div className="main-header-right" ></div>
          </div>
        </div>

        <div className="info" >
          <div className="info-column-1">
            <Field name="name" label="Name" labelAfter="true" size="20" />
            <Field name="pronouns" label="Pronouns" labelAfter="true" size="20" />
            <Field name="origin" label="Origin" labelAfter="true" size="20" />
            <Field name="role" label="Role" labelAfter="true" size="20" />
            <Field name="level" label="Level" labelAfter="true" size="20" />
          </div>

          <div className="info-column-2" >
            <FieldArea name="description" label="Description" labelAfter="true" size="20" rows={4} />
          </div>

          <div className="info-column-3" >
            <Field name="languages" label="Languages" labelAfter="true" size="20" />

            <div className="info-faction" >
              <div className="title-faction" >FACTION</div>
              <FieldRadio
                className="faction"
                name={"faction"}
                values={['Autobot', 'Decepticon']}
                labels={['Autobots', 'Decepticons']}
                images={[Autobot, Decepticon]}
                labelAfter="true" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/***** *****/
export default CharacterInfo;
