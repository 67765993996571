import React from 'react';
import ReactDOM from 'react-dom';

import Field from './../../field.js';
import FieldArea from './../../fieldarea.js';
import FieldRadio from './../../fieldradio.js';




/* */
class Armor extends React.Component {
  /* */
  render( ) {
    var armor           = [ 1, 2 ];

    return(
      <div className="armor-wrapper" >
        <div className="armor" >
          <div className="armor-content" >
            <div className="armor-title" >ARMOR</div>
            {armor.map((value,index) => {
              return(
                <div className={'armor-row armor-row-' + value} >
                  <Field name={'armor-field armor-type-' + value} label="Type" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'armor-field armor-description-' +value} label="Descrption" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'armor-field armor-effect-' + value} label="Armor" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'armor-field armor-traits-' + value} label="Traits" noLabel={value > 1 ? "true" : "false"} size="20" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Armor;
