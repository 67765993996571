import React from 'react';
import ReactDOM from 'react-dom';

import Field from './../../field.js';
import FieldArea from './../../fieldarea.js';
import FieldRadio from './../../fieldradio.js';




/* */
class AltMode extends React.Component {
  /* */
  render( ) {
    var altModeAttacks  = [ 1, 2 ];

    return(
      <div className="alt-mode-wrapper" >
        <div className="alt-mode" >
          <div className="alt-mode-content" >
            <div className="alt-mode-title" >ALT MODE</div>
              <Field name={'alt-mode-field-origin-name'} label="Origin Name" labelAfter="true" />
              <Field name={'alt-mode-field-crew'} label="Crew" labelAfter="true" />
              <Field name={'alt-mode-field-health'} label="Health" labelAfter="true" />
              <Field name={'alt-mode-field-size'} label="Size" labelAfter="true" />
              <Field name={'alt-mode-field-movement'} label="Movement" labelAfter="true" />
              <Field name={'alt-mode-field-features'} label="Features" labelAfter="true" />
              <Field name={'alt-mode-field-movement2'} label="Movement" labelAfter="true" />

              <div className="alt-mode-attacks-wrapper" >
                <div className="alt-mode-attacks" >
                  <div className="alt-mode-attacks-content" >
                    <div className="alt-mode-attacks-title" >ATTACKS</div>
                    {altModeAttacks.map((value,index) => {
                      return(
                        <div className={'alt-mode-attacks-row alt-mode-attacks-row-' + value} >
                          <Field className="alt-mode-attacks-field" name={'alt-mode-attacks-weapon-' + value} label="WEAPON" noLabel={value > 1 ? "true" : "false"}  />
                          <Field className="alt-mode-attacks-field" name={'alt-mode-attacks-range-' +value} label="RANGE" noLabel={value > 1 ? "true" : "false"}  />
                          <Field className="alt-mode-attacks-field" name={'alt-mode-attacks-effect-' + value} label="EFFECTS" noLabel={value > 1 ? "true" : "false"}  />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <Field className="alt-mode-field-defense" name={'alt-mode-field-toughness'} label="Toughness" labelAfter="true"  />
              <Field className="alt-mode-field-defense" name={'alt-mode-field-evasion'} label="Evasion" labelAfter="true"  />
              <Field className="alt-mode-field-defense" name={'alt-mode-field-willpower'} label="Willpower" labelAfter="true"  />
              <Field className="alt-mode-field-defense" name={'alt-mode-field-cleverness'} label="Cleverness" labelAfter="true"  />
          </div>
        </div>
      </div>
    );
  }
}

export default AltMode;
