import './fonts/sf_transrobotics_bold_italic-webfont.woff2';
import './fonts/sf_transrobotics_bold_italic-webfont.woff';
import './fonts/sf_transrobotics_bold-webfont.woff2';
import './fonts/sf_transrobotics_bold-webfont.woff';
import './fonts/sf_transrobotics_italic-webfont.woff2';
import './fonts/sf_transrobotics_italic-webfont.woff';
import './fonts/sf_transrobotics-webfont.woff2';
import './fonts/sf_transrobotics-webfont.woff';

import './css/main.css';

import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";

import Login from "./components/Login";
import Register from "./components/Register";

import DBCharacter from './DBCharacter.js';
import DBCharacter2 from './DBCharacter2.js';

// import AuthVerify from "./common/AuthVerify";
import Emitter from './common/emitter.js';

const App = () => {
  return(
    <div className="container mt-3">
      <Routes>
        <Route exact path={"/"} element={<Login />} />
        <Route exact path={"/character"} element={<DBCharacter />} />
        <Route exact path={"/character2"} element={<DBCharacter2 />} />
        <Route exact path={"/register"} element={<Register />} />
      </Routes>
    </div>
  );
};

export default App;
