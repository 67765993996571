import axios from 'axios';

import { API_AUTH_URL } from './config.api.js';

/* */
const register = (username, email, password) => {
  return axios.post( API_AUTH_URL + 'register', {
    username, email, password
  });
};

/* */
const login = (username, password) => {
  return axios
    .post( API_AUTH_URL + 'signin', {
      username,
      password
    })
    .then((response) => {
      if( response.data.status == 'ERROR' ) {
        throw new Error( response.data.message );
      }

      if( response.data.status == 'SUCCESS' ) {
        localStorage.setItem( 'username', JSON.stringify( response.data.username ) );
        localStorage.setItem( 'token', JSON.stringify( response.data.token ) );
        localStorage.setItem( 'characterID', JSON.stringify( response.data.characterID ) );
      }
    })

};

/* */
const logout = () => {
  localStorage.removeItem( 'user' );
  localStorage.removeItem( 'token' );

  return axios.post( API_AUTH_URL + 'signout' ).then( (response) => {
    return response.data;
  });
};

/* */
const getUsername = () => {
  return JSON.parse( localStorage.getItem( 'username' ) );
}
/* */
const getCurrentUser = () => {
  return JSON.parse( localStorage.getItem( 'user' ) );
};

/* */
const getToken = () => {
  return JSON.parse( localStorage.getItem( 'token' ) );
}

/* */
const validateToken = () => {
  var username  = AuthService.getUsername( );
  var token     = AuthService.getToken( );

  return axios.post( API_AUTH_URL + 'validate-token', {
    username, token
  });
}

/* */
const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  getUsername,
  getToken,
  validateToken,
}

export default AuthService;
