import React from 'react';
import ReactDOM from 'react-dom';

import Field from './../../field.js';
import FieldArea from './../../fieldarea.js';
import FieldRadio from './../../fieldradio.js';




/* */
class PGB extends React.Component {
  /* */
  render( ) {    
    return(
      <div className="perks-gear-bonds-wrapper" >
        <div className="perks-gear-bonds" >
          <FieldArea name="perks" label="Perks" size="20" rows={4} />
          <FieldArea name="gear" label="Gear" size="20" rows={4} />
          <FieldArea name="bonds" label="Background Bonds" size="20" rows={4} />
        </div>
      </div>
    );
  }
}

export default PGB;
