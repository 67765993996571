import './../css/index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import CharacterInfo from './character-sheet/characterinfo.js';
import Health from './character-sheet/health.js';
import Attack from './character-sheet/attack.js';
import Essence from './character-sheet/essence.js';
import Skill from './character-sheet/skill.js';
import PGB from './character-sheet/pgb.js';
import Hardpoints from './character-sheet/hardpoints.js';
import Armor from './character-sheet/armor.js';
import AltMode from './character-sheet/altmode.js';
import CharacterNotes from './character-sheet/characternotes.js';

import CharacterList from './CharacterList.js';
import ControlPanel from './ControlPanel.js';

import Field from '../field.js';
import FieldArea from '../fieldarea.js';
import FieldRadio from '../fieldradio.js';

import CharacterService from './../services/character.service.js';
import Emitter from './../common/emitter.js';


/* */
class Character extends React.Component {
  /* */
  constructor( props ) {
    super( props );

    this.state = {
      essencePoints: 0,
    }
  }

  /* */
  componentDidMount( ) {
    /* Listen for character:save */
    Emitter.on( 'character:save', function(d) {
      CharacterService.setCharacterValue( localStorage.getItem( 'characterID' ), d.name, d.value );
    });
  }

  /* */
  render( ) {
    return(
      <div className="sheet-wrapper" >
        <form name="form-character-sheet" >
          <div className="sheet" >
            <div className="sheet-contents" >
              <div className="sheet-background" > </div>

              </div>
          </div>
        </form>
      </div>
    )
  }
}

export default Character;
