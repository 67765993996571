import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import CharacterService from './../services/character.service.js';
import CampaignService from './../services/campaign.service.js';

import './../css/character-list.css';
import robotAvatar from './../images/robot-avatar.png';



/* */
class CampaignCharacterList extends React.Component {
  constructor( props ) {
      super( props );

      /* */
      this.state = {
        party: [ ],
      }

      this.selectCharacter    = this.selectCharacter.bind( this );
  }

  /* */
  componentDidMount( ) {
    var c = CampaignService.getParty( 1 );

    c.then( (response) => {
      this.setState({ party: response.data.characters });
    });
  }

  /* */
  selectCharacter( e, char ) {
    e.preventDefault( );

    localStorage.setItem( 'characterID', char.id );
    localStorage.setItem( 'characterIsAlly', true );
    window.location.reload( );
    return;
  }

  /* */
  render( ) {
    return(
      <div className="character-list-wrapper" >
        <div className="character-list" >
          <div className="character-list-title" >
            PARTY
          </div>
          {this.state.party.map( (e,i) => {
            /***** *****/
            //if( e.id != localStorage.getItem( 'characterID' ) )
              return(
                <div className="character-list-item" >
                  <div className="character-list-item-pic" >
                  <Link to="/character" onClick={(v) => {this.selectCharacter(v, e)}} >
                    <img src={robotAvatar} />
                  </Link>
                  </div>
                  <div className="character-list-item-link" >
                    <Link to="/character" onClick={(v) => {this.selectCharacter(v, e)}} >{e.name}</Link>
                  </div>
                </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CampaignCharacterList;
