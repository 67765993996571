import React from 'react';
import ReactDOM from 'react-dom';

import Field from './../../field.js';
import FieldArea from './../../fieldarea.js';
import FieldRadio from './../../fieldradio.js';



/*
      <div className="separator-wrapper-main" >
      <div className="separator-wrapper" >
        <div className="separator" ></div>
        <div className="separator" ></div>
        <div className="separator" ></div>
        <div className="separator" ></div>
        <div className="separator" ></div>
        <div className="separator" ></div>
        <div className="separator" ></div>
        <div className="separator" ></div>
      </div>
      </div>
*/
/* */
class Health extends React.Component {
  /* */
  render( ) {
    var dmgBtns         = [];

    for (var i = 0; i < 20; i++) {
      dmgBtns.push(<FieldRadio className={"damage" + i} name={'damage-' + i} values={[1]} labels={['']} value={1} />);
    }

    return(
      <div className="health-wrapper" >
        <div className="health" >
          <div className="health-column-1" >
            <FieldArea name="influences" label="Influences" labelAfter="true" size="20" rows={3} />
            <FieldArea name="hangups" label="Hangups" labelAfter="true" size="20" rows={3} />
          </div>

          <div className="health-column-2" >
            <Field name="energon" label="Energon" labelAfter="true" size="20" />
            <Field name="movement" label="Movement" labelAfter="true" size="20" />
            <Field name="hlth" label="Health" labelAfter="true" size="20" />

            <div className="damage-wrapper" >
              {dmgBtns}
              <div className={'field-label-damage field-label'} >
                DAMAGE
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Health;
