import React from 'react';
import ReactDOM from 'react-dom';

import Field from './../../field.js';
import FieldArea from './../../fieldarea.js';
import FieldRadio from './../../fieldradio.js';




/* */
class Attack extends React.Component {
  /* */
  render( ) {
    var attacks         = [ 1, 2, 3, 4, 5 ];

    return(
      <div className="attack-wrapper" >
        <div className="attack" >
          <div className="attack-content" >
            <div className="attack-title" >ATTACK</div>
            {attacks.map((value,index) => {
              return(
                <div className={'attack-row attack-row-' + value} >
                  <Field name={'attack-field attack-name-' + value} label="Name" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'attack-field attack-range-' +value} label="Range" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'attack-field attack-attack-' + value} label="Attack" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'attack-field attack-effects-' + value} label="Effects" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'attack-field attack-notes-' + value} label="Notes" noLabel={value > 1 ? "true" : "false"} size="20" />
                </div>
              );
            })}

          </div>
        </div>
      </div>
    )
  }
}

export default Attack;
