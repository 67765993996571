import React from 'react';
import ReactDOM from 'react-dom';

import Emitter from './common/emitter.js';
import CharacterService from './services/character.service.js';

/*
 *
 */
class Field extends React.Component {
  /* */
  constructor( props ) {
    super( props );

    /* */
    this.state = {
      name:   this.props.name,
      value:  '',
      inForm: false,
      label: this.props.label,
      labelAfter: this.props.labelAfter == 'true' ? true : false,
      noLabel: this.props.noLabel == 'true' ? true : false,
      className: this.props.className,
    }

    this.handleInputChange  = this.handleInputChange.bind( this );
    this.handleBlur         = this.handleBlur.bind( this );
    this.loadEmitter        = this.loadEmitter.bind( this );

    this.loadEmitter( );
  }

  loadEmitter( ) {
    var me = this;

    Emitter.on( 'character:load', function( c ) {
      Object.keys(c).map((v, n) => {
        if( v == me.state.name ) {
          var tmp = c[v];
          me.setState({ value: tmp});
          return;
        }
      });
    });
  }

  /* */
  handleInputChange( event ) {
    const target  = event.target;
    const value   = target.value;
    const name    = target.name;

    this.setState({
      value: value
    });
  }

  /* */
  handleBlur( event ) {
    const target  = event.target;
    const value   = target.value;
    const name    = target.name;

    if( value != this.state.value && this.state.value != null )
      return;

    Emitter.emit( 'character:save', { name: name, value: value } );
  }

  /* */
  render( ) {
    var a = '';
    var b = <label for={this.state.name} className={'field-label-' + this.state.name + ' field-label'} >
              {this.state.label}
            </label>;

    if( this.state.labelAfter ) {
      a = b;
      b = '';
    }

    if( this.state.noLabel ) {
      a = '';
      b = '';
    }

    return(
      <div className={this.state.className + "-wrapper " + this.state.name + '-wrapper field-wrapper'} >
        {b}
        <input
          name    = {this.state.name}
          type    = "text"
          value   = {this.state.value}
          onChange= {this.handleInputChange}
          onBlur  = {this.handleBlur}
          size    = {this.props.size}
        />
        {a}
      </div>
    );
  }
}

export default Field;
