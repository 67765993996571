import './../css/control-panel.css';

import React from 'react';
import ReactDOM from 'react-dom';

import CharacterList from './CharacterList.js';
import CampaignCharacterList from './Campaign.CharacterList.js';

import Field from '../field.js';
import FieldArea from '../fieldarea.js';
import FieldRadio from '../fieldradio.js';

import CharacterService from './../services/character.service.js';
import Emitter from './../common/emitter.js';




/* */
class ControlPanel extends React.Component {
  /* */
  render( ) {
    return(
      <div className="control-panel-wrapper" >
        <div className="control-panel" >
          <CharacterList />
          <CampaignCharacterList />
        </div>
      </div>
    );
  }
}

export default ControlPanel;
