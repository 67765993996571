import './../css/index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import CharacterInfo from './character-sheet/characterinfo.js';
import Health from './character-sheet/health.js';
import Attack from './character-sheet/attack.js';
import Essence from './character-sheet/essence.js';
import Skill from './character-sheet/skill.js';
import PGB from './character-sheet/pgb.js';
import Hardpoints from './character-sheet/hardpoints.js';
import Armor from './character-sheet/armor.js';
import AltMode from './character-sheet/altmode.js';
import CharacterNotes from './character-sheet/characternotes.js';

import CharacterList from './CharacterList.js';
import ControlPanel from './ControlPanel.js';

import Field from '../field.js';
import FieldArea from '../fieldarea.js';
import FieldRadio from '../fieldradio.js';

import CharacterService from './../services/character.service.js';
import CampaignService from './../services/campaign.service.js';
import Emitter from './../common/emitter.js';


/* */
class CharacterSheet extends React.Component {
  /* */
  constructor( props ) {
    super( props );

    this.state = {
      essencePoints: 0,
    }
  }

  /* */
  componentDidMount( ) {
    var service     = null;
    var character   = null;
    var isAlly      = localStorage.getItem( 'characterIsAlly' );
    var characterID = localStorage.getItem( 'characterID' );

    if( isAlly == 'true' ) {
      character = CampaignService.getAlly( 1, characterID );
    }
    else {      
      character = CharacterService.getCharacter( characterID );

      Emitter.on( 'character:save', function(d) {
        console.log( '$$$$$$$$$$$$$$$$$$$$$$' + characterID );
        CharacterService.setCharacterValue( characterID, d.name, d.value );
      });
    }

    character.then((response) => {
        Emitter.emit( 'character:load', response.data );
    });
  }

  /* */
  render( ) {
    return(
      <div className="sheet-wrapper" >
        <form name="form-character-sheet" >
          <div className="sheet" >
            <div className="sheet-contents" >
              <div className="sheet-background" > </div>
              <CharacterInfo />
              <Health />
              <Attack />

              <div className='essence-wrapper-main' >
                <Essence
                  name="STRENGTH"
                  defense="TOUGHNESS"
                  skills={['ATHLETICS','BRAWN','CONDITIONING','INTIMIDATION', 'MIGHT']}
                  simple={['false', 'false', 'true', 'false', 'false']}
                />
                <Essence
                  name="SPEED"
                  defense="EVASION"
                  skills={['ACROBATICS', 'DRIVING', 'FINESSE', 'INFILTRATION', 'INITIATIVE', 'TARGETING']}
                  simple={['false', 'false', 'false', 'false', 'true', 'false']}
                />
                <Essence
                  name="SMARTS"
                  defense="WILLPOWER"
                  skills={['ALTERNESS', 'CULTURE', 'SCIENCE', 'SURVIVAL', 'TECHNOLOGY']}
                  simple={['false', 'false', 'false', 'false', 'false']}
                />
                <Essence
                  name="SOCIAL"
                  defense="CLEVERNESS"
                  skills={['ANIMAL HANDLING', 'DECEPTION', 'PERFORMANCE', 'PERSUASION', 'STREETWISE']}
                  simple={['false', 'false', 'false', 'false', 'false']}
                />
              </div>

              <PGB />

              <Hardpoints />

              <Armor />

              <AltMode />
              <AltMode />

              <CharacterNotes />
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default CharacterSheet;
