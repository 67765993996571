import React from 'react';
import ReactDOM from 'react-dom';
import Field from './field.js';



class FieldArea extends Field {
  /* */
  constructor( props ) {
    super( props );

    /* */
    /* */
    this.state = {
      name:   this.props.name,
      value:  null,
      inForm: false,
      label: this.props.label,
      labelAfter: this.props.labelAfter == 'true' ? true : false,
      noLabel: this.props.noLabel == 'true' ? true : false,
      className: this.props.className,
      rows:       this.props.rows,
    }
  }

  /* */
  handleInputChange( event ) {
    const target  = event.target;
    const value   = target.value;
    const name    = target.name;

    this.setState({
      value: value
    });

    target.style.height = '';
    target.style.height = target.scrollHeight + 'px';
  }

  /* */
  render( ) {
    var a = '';
    var b = <label for={this.state.name} className="field-label" >
              {this.state.label}
            </label>;

    if( this.state.labelAfter ) {
      a = b;
      b = '';
    }

    return(
      <div className={this.state.name + '-wrapper field-wrapper'} >
        {b}
        <textarea
          name    = {this.state.name}
          value   = {this.state.value}
          onChange= {this.handleInputChange}
          onBlur  = {this.handleBlur}
          rows    = {this.state.rows}
          onInput = {this.props.oninput}
        />
        {a}
      </div>
    );
  }
}

export default FieldArea;
