import React from 'react';
import ReactDOM from 'react-dom';

import Field from './../../field.js';
import FieldArea from './../../fieldarea.js';
import FieldRadio from './../../fieldradio.js';




/* */
class Hardpoints extends React.Component {
  /* */
  render( ) {
    var hardpoints      = [ 1, 2, 3, 4, 5, 6 ];

    return(
      <div className="hardpoints-wrapper" >
        <div className="hardpoints" >
          <div className="hardpoints-content" >
            <div className="hardpoints-title" >HARDPOINTS</div>
            {hardpoints.map((value,index) => {
              return(
                <div className={'hardpoints-row hardpoints-row-' + value} >
                  <Field name={'hardpoints-name-' + value} label="NAME" className="hardpoints-field" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'hardpoints-range-' +value} label="RANGE" className="hardpoints-field" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'hardpoints-hardpoint-' + value} label="HARDPOINTS" className="hardpoints-field" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'hardpoints-traits-' + value} label="TRAITS" className="hardpoints-field" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'hardpoints-attack-' + value} label="ATTACK" className="hardpoints-field" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'hardpoints-effects-' + value} label="EFFECTS" className="hardpoints-field" noLabel={value > 1 ? "true" : "false"} size="20" />
                  <Field name={'hardpoints-alt-effects-' + value} label="ALT EFFECTS" className="hardpoints-field" noLabel={value > 1 ? "true" : "false"} size="20" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Hardpoints;
