import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";

import CharacterSheet from './components/CharacterSheet.js';
import ControlPanel from './components/ControlPanel.js';

const DBCharacter = () => {
  return(
    <div>
      <CharacterSheet />
      <ControlPanel / >
    </div>

  );
};

export default DBCharacter;
