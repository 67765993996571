import React from 'react';
import ReactDOM from 'react-dom';
import Field from './../../field.js';
import FieldRadio from './../../fieldradio.js';

class Specialization extends React.Component {
  /* */
  constructor( props ) {
      super( props );

      this.state = {
        specialization: ''
      }
  }

  render( ) {
    return(
      <div className={"specialization-main specialization-" + this.props.skill} >
        <FieldRadio className="specialization-radio-wrapper" name={"specialization-radio-" + this.props.skill + "-" + this.props.number} values={[this.props.number]} labels={['']} />

        <Field
          className="specialization"
          name={"specialization-" + this.props.skill + '-' + this.props.number}
          size="20"
        />
      </div>
    );
  }
}

export default Specialization;
