import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import CharacterService from './../services/character.service.js';

import './../css/character-list.css';
import robotAvatar from './../images/robot-avatar.png';



/* */
class CharacterList extends React.Component {
  constructor( props ) {
      super( props );

      /* */
      this.state = {
        characters: [ ],
      }

      this.selectCharacter    = this.selectCharacter.bind( this );
      this.createNewCharacter = this.createNewCharacter.bind( this );
  }

  /* */
  createNewCharacter( ) {
    var tgt = this;
    var cID = CharacterService.createCharacter( );

    //
    cID.then((response) => {
      var c = CharacterService.getCharacters( );

      c.then( (r) => {
        if( r.data.status == 'SUCCESS' )
          tgt.setState({ characters: r.data.characters });
      });
    });
  }

  /* */
  componentDidMount( ) {
    var c = CharacterService.getCharacters( );

    c.then( (response) => {
      this.setState({ characters: response.data.characters });
    });
  }

  /* */
  selectCharacter( e, char ) {
    e.preventDefault( );

    localStorage.setItem( 'characterID', char.id );
    localStorage.setItem( 'characterIsAlly', false );
    window.location.reload( );
    return;
  }

  /* */
  render( ) {
    return(
      <div className="character-list-wrapper" >
        <div className="character-list" >
          <div className="character-list-title" >
            CHARACTER LIST
          </div>
          <div className="character-list-item" >
            <div className="character-list-item-pic" >
              <Link to="/character" onClick={this.createNewCharacter} >
                <img src={robotAvatar} />
              </Link>
            </div>
            <div className="character-list-item-link" >
              <Link to="/character" onClick={this.createNewCharacter} >Add Character</Link>
            </div>
          </div>
          {this.state.characters.map( (e,i) => {
            /***** *****/
            //if( e.id != localStorage.getItem( 'characterID' ) )
              return(
                <div className="character-list-item" >
                  <div className="character-list-item-pic" >
                  <Link to="/character" onClick={(v) => {this.selectCharacter(v, e)}} >
                    <img src={robotAvatar} />
                  </Link>
                  </div>
                  <div className="character-list-item-link" >
                    <Link to="/character" onClick={(v) => {this.selectCharacter(v, e)}} >{e.name}</Link>
                  </div>
                </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CharacterList;
