import React from 'react';
import ReactDOM from 'react-dom';
import Skill from './skill.js';
import Field from './../../field.js';

/*
 *
 */
class Essence extends React.Component {
  /* */
  constructor( props ) {
    super( props );
  }

  /* */
  render( ) {
    return(
      <div className="essence-wrapper" >
        <div className="essence" >
          <div className="essence-info" >
            <div className="essence-title">
              {this.props.name} <Field className="essence-val" name={"essence-val-" + this.props.name} size="2" />
            </div>

            <div className="defense-wrapper" >
              <div className="defense">
                {this.props.defense} <Field className="defense-val" name={"defense-val-" + this.props.defense} size="2" />
              </div>

              <div className="defense-calc-wrapper">
                <div className="defense-base defense">10</div>
                <div className="defense-plus">+</div>

                <Field className="defense-essence-val" name={"defense-essence-val-" + this.props.name} size="2" />
                <div className="defense-plus">+</div>

                <Field className="defense-perks-val" name={"defense-perks-val-" + this.props.name} size="2" />
                <div className="defense-plus">+</div>

                <Field className="defense-bonus-val" name={"defense-bonus-val-" + this.props.name} size="2" />
              </div>
            </div>

            {this.props.skills.map((skill,index) => {
              return( <Skill name={skill} simple={this.props.simple[index]} /> );
            } ) }
          </div>
        </div>
      </div>
    );
  }
}

export default Essence;
