import React from 'react';
import ReactDOM from 'react-dom';

import Field from './../../field.js';
import FieldArea from './../../fieldarea.js';
import FieldRadio from './../../fieldradio.js';




/* */
class CharacterNotes extends React.Component {
  /* */
  render( ) {
    return(
      <div className="notes-wrapper" >
        <div className="notes" >
          <div className="notes-content" >
            <div className="notes-title" >NOTES</div>
            <FieldArea name="notes" label="Notes" labelAfter="true" size="20" rows={4} />
          </div>
        </div>
      </div>
    );
  }
}

export default CharacterNotes;
